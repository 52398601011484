import {action, observable} from "mobx";

export default class Store {
    @observable endpoint = 'https://game-back.mimikogames.com/mine/';
    @observable gameTable = [];

    /**
     *  state.status
     *  0 init (bu kısımda oyun var mı hesabı yapılacak)
     *  1 ide
     *  2 loading
     *  3 cashin yapılabilir ready
     *  4 oyun bitti
     */
    @observable status = 0;
    @observable clickIndex = -1;

    @observable errorMsg = '';
    @observable isError = false;

    @observable betAmount = 0.000001;
    @observable balance = 0;
    @observable mineCount = 5;
    @observable multiplier = 1;
    @observable nextMultiplier = 1;
    @observable winAmount = 1;
    @observable isWinner = false;

    // :: currency işlemleri
    @observable currencies = [];
    @observable numberLimit= 2 ;
    @observable currencyName= '';
    @observable increaseRate= 0.000001;
    @observable maxBet= 0.1;
    @observable minBet= 0.000001;


    @action.bound
    async select(card) {
        if (card.status === 0 && this.status === 3) {
            this.status = 2; //loading

            const rawResponseStart = await fetch(this.endpoint + 'bet', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "Token": window.mimikoClient.Token,
                    "SiteId": Number(window.mimikoClient.SiteId),
                    "CurrencyId": Number(window.mimikoClient.CurrencyId),
                    "SelectedIndex": Number(card.index)
                })
            });
            const response = await rawResponseStart.json();

            card.status = 1;
            card.selected = true;
            this.clickIndex = card.index;
            this.multiplier = response.Multiplier;
            this.nextMultiplier = response.NextMultiplier;
            this.balance = response.Balance;

            if (response.MineFound && response.MineFound === true) {
                card.type = 1;
                this.status = 1;
                this.setMines(response.MineIndexes)
            } else {
                this.status = 3;
                card.type = 0;
            }


        }
    }

    @action.bound setMines(mines){
        this.gameTable.map(i => {
            i.type = 0;
        })
        mines.map(i => {
            this.gameTable[i].type = 1;
        })
        this.gameTable.map(i => {
            i.status = 1;
        })
    }


    @action.bound gameTableUpdate(data) {
        this.gameTable = data;
    }

    @action.bound setGameState(state) {
        this.status = state;
    }

    @action.bound resetGame() {
        this.gameTable.map(i => {
            i.status = 0;
            i.selected = false;
        })
        this.clickIndex = -1;
    }




    @action.bound
    async getBalance() {
        let data = {
            "Token":window.mimikoClient.Token,
            "SiteId":Number(window.mimikoClient.SiteId),
            "CurrencyId":Number(window.mimikoClient.CurrencyId),
        };
        const rawResponseStart = await fetch('https://game-back.mimikogames.com/player/data', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const response = await rawResponseStart.json();
        this.balance = response.Balance;
    }

    @action.bound
    setCurrencyOperation(){

        this.currencies.map(i=>{
            if(i.CurrencyId === Number(window.mimikoClient.CurrencyId)){
                /**
                 * CurrencyId: 1000
                 CurrencyName: "BTC"
                 Decimal: 14
                 Id: 2
                 IncreaseRate: 0.000001
                 MaxBet: 0.1
                 MinBet: 0.000001
                 SiteId: 123
                 */

                this.currencyName = i.CurrencyName;
                this.numberLimit = i.Decimal;
                this.increaseRate = i.IncreaseRate;
                this.maxBet = i.MaxBet;
                this.minBet = i.MinBet;
                this.betAmount = i.MinBet;
                console.log(this.betAmount,i.MinBet)

            }
        })
    }


    @action.bound
    setBetAmount(p){
        this.betAmount = Number(p);
    }


    @action.bound
    async getCurrencies() {


        let data = {
            "Token":window.mimikoClient.Token,
        };


        const rawResponseStart = await fetch('https://game-back.mimikogames.com/site/'+window.mimikoClient.SiteId+'/currencies', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const responseStart = await rawResponseStart.json();
        if(responseStart.Currencies){
            this.currencies = responseStart.Currencies;
            this.setCurrencyOperation();
        }
    }
}