<template>
  <div class="game-sidebar">
    <div class="row">
      <Balance :balance="$attrs.state.balance" :currency="$attrs.state.currencyName" :numberLimit="$attrs.state.numberLimit"/>
    </div>
    <BetLayout :balance="$attrs.state.balance"
               :minBet="$attrs.state.minBet"
               :maxBet="$attrs.state.maxBet"
               :state="$attrs.state"
               :increaseRate="$attrs.state.increaseRate"
               :currency="$attrs.state.currencyName"
               :numberLimit="$attrs.state.numberLimit"
               :isDisabled="isDisabled"
    />

    <div class="row label">
      <span>Mines</span>
    </div>
    <div class="row">
      <select v-model="$attrs.state.mineCount" :disabled="isDisabled" v-bind:class="{'disabled': isDisabled}">
        <option v-for="i in 24" v-bind:key="i">{{ i }}</option>
      </select>
    </div>
    <div class="row label" v-if="canRandomSelect ">
      <span>Total Profit ({{$attrs.state.multiplier.toFixed($attrs.state.numberLimit)}}x)</span>
    </div>
    <div class="row"  v-if="canRandomSelect ">
      <input type="number" :value="($attrs.state.betAmount*$attrs.state.multiplier).toFixed($attrs.state.numberLimit)" disabled="disabled">
    </div>
    <div class="row label next-multiplier" v-if="canRandomSelect ">
      <span>Next ({{$attrs.state.nextMultiplier.toFixed($attrs.state.numberLimit)}}x)</span>
    </div>
    <div class="row">
      <span class="action btn secondary" @click="randomSelect" v-if="canRandomSelect ">Pick random tile</span>
    </div>
    <div class="row">
      <span class="action btn" @click="gameControl" v-bind:class="{'disabled': isDisabled && cannotCashout}"
            v-html="btnText"/>
    </div>
  </div>
</template>

<script>

import Balance from "./Balance";
import BetLayout from "./BetLayout";
export default {
  name: 'Controller',
  components: {BetLayout, Balance},
  data() {
    return {
      betStep: 0.000001,
      numberLimit: 14,
      bombIcon: require('../assets/miniBomb.svg'),
    }
  },
  mounted() {

  },
  methods: {
    balanceFormat: function (n) {
      return Number(n).toFixed(this.$attrs.state.decimal).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    showBalance: function ($balance){
      return $balance;
    },
    takeDouble: function () {
      if (!this.isDisabled) {
        this.$attrs.state.betAmount = 2 * this.$attrs.state.betAmount;
      }
    },
    takeHalf: function () {
      if (!this.isDisabled) {
        this.$attrs.state.betAmount = this.$attrs.state.betAmount / 2;
      }
    },
    betAmountFormat: function () {
      return Number(this.$attrs.state.betAmount);
    },
    randomSelect: function () {
      let status = this.$attrs.state.status;
      if (status !== 2) {
        let random = Math.floor(Math.random() * 25);

        if (!this.$attrs.state.gameTable[random].selected) {
          this.$attrs.state.select(this.$attrs.state.gameTable[random]);
        } else {
          this.randomSelect();
        }
      }
    },
    gameControl: async function () {
      let status = this.$attrs.state.status;
 
      if (status === 1 || status === 4) {

        this.$attrs.state.isWinner = false;
        this.$attrs.state.multiplier = 1.00;
        let data = {
          "Token":window.mimikoClient.Token,
          "SiteId":Number(window.mimikoClient.SiteId),
          "MineAmount": Number(this.$attrs.state.mineCount),
          "CurrencyId": Number(window.mimikoClient.CurrencyId),
          "BetAmount": this.betAmountFormat()
        };

        const rawResponseStart = await fetch(this.$attrs.state.endpoint + 'start', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
        const responseStart = await rawResponseStart.json();
        if(responseStart.Error){
          console.warn(responseStart.Error)
        }

        //bet
        this.$attrs.state.setGameState(3);
        this.$attrs.state.resetGame();
        this.$attrs.state.nextMultiplier = responseStart.NextMultiplier;


      }
      if (status === 3) {
        if(this.cannotCashout){
          return false;
        }
        //cashout işlemleri
       let  data = {
          "Token": window.mimikoClient.Token,
          "SiteId": Number(window.mimikoClient.SiteId),
          "CurrencyId": Number(window.mimikoClient.CurrencyId),
        };
        this.$attrs.status = 2;
        const rawResponseStart = await fetch(this.$attrs.state.endpoint + 'cashout', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
        const responseStart = await rawResponseStart.json();
        if (responseStart.Error) {
          console.warn(responseStart.Error)
        }else{
          this.$attrs.state.winAmount = responseStart.WinAmount;
          this.$attrs.state.isWinner = true;
          this.$attrs.state.balance = responseStart.Balance;
          this.$attrs.state.multiplier = responseStart.Multiplier;
          this.$attrs.state.nextMultiplier = responseStart.NextMultiplier;
          this.$attrs.state.setMines(responseStart.MineIndexes)
        }
        this.$attrs.state.setGameState(4);
      }
    }
  },
  computed: {
    canRandomSelect: function () {
      switch (this.$attrs.state.status) {
        case 1:
          return false
        case 4:
          return false
        default:
          return true;
      }
    },
    cannotCashout: function () {
      let totalSelected = 0;
      this.$attrs.state.gameTable.map(i => {
        if (i.status === 1) {
          totalSelected++;
        }
      })
      return totalSelected <= 0;

    },
    isDisabled: function () {
      switch (this.$attrs.state.status) {
        case 1:
          return false
        case 4:
          return false
        default:
          return true;
      }
    },
    btnText: function () {
      switch (this.$attrs.state.status) {
        case 0:
          return 'Initializing';
        case 1:
          return  'Bet';
        case 4:
          return 'Bet';
        case 3:
          return 'Cashout';
        case 2: return '<span><img class="mini-bomb" src="'+this.bombIcon+'" /></span>';
        default:
          return '';
      }
    }
  },
  watch: {
    "$attrs.state.status": function (x) {
      if (x === 2) {

        console.log('event test')
      }
    },
    "$attrs.state.betAmount": function (x) {
      this.$attrs.state.betAmount = Number(x).toFixed(this.numberLimit)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.next-multiplier {
  color: #15e703;
  font-size: 20px;
}

.next-multiplier>span {
  padding-bottom: 10px;
}
.game-sidebar {
  background: #213743;
}

.row select,
.row input {
  outline: none !important;
  font-size: 15px;
  padding: 5px;
  border-radius: 6px;
  background: rgb(0 0 0 / 20%);
  color: #fff;
  border: 3px solid rgb(255 255 255 / 22%);
  font-weight: 800;
  width: 100%;
}

.action,
.row input {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

span.btn {
  background: transparent;
  padding: 10px;
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: bold;
}

.row {
  display: flex;
  padding: 5px 10px;
}

.row.btn {
  background: #15232c;
  margin: 5px 10px;
  padding: 5px;
  border-radius: 4px;
}

select.disabled,
.row.btn.disabled > *,
.action.btn.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.row > * {
  margin: 5px;
}

span.btn:hover {
  background: #fff;
  color: #000;
  border-radius: 4px;
}

span.btn sub {
  position: absolute;
  padding-top: 1px;
  padding-left: 2px;
}

.row.table > * {
  display: flex;
  width: 100%;
}

.row.table > *:last-child {
  text-align: right;
  justify-content: right;
}

.row.label {
  padding: 0 10px;
  margin-bottom: -10px;
}

.row select > option {
  background: #15232c;
}

.row select:hover,
.row input:hover {
  border: 3px solid #2196f3;
}

span.action.btn.secondary {
  background: #4d5a62;
  color: #fff;
  border-top: 1px solid hsl(0deg 0% 56% / 32%);
  border-bottom: 1px solid rgb(0 0 0 / 53%);
}

.action.btn {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  color: #000000;
  font-weight: 500;
  background: #00e701;
  box-shadow: 0 0 21px 5px rgb(0 0 0 / 25%);
  min-height: 40px;
  box-sizing: border-box;
}

.action.btn:hover {
  background: #2196f3;
}


</style>
