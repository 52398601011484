<template>
  <div class="bet-layer">
    <div class="row table" v-if="balance">
      <span>Bet Amount</span>
      <span></span>
    </div>
    <div class="row btn" v-bind:class="{'disabled': isDisabled}">
      <input type="number" v-model="betAmount" :disabled="isDisabled" :step="increaseRate">

      <span class="btn" @click="takeHalf">½</span>
      <span class="btn" @click="takeDouble">2<sub>x</sub></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BetLayout',
  props: {
    isDisabled: {
      type: Boolean,
      require: true
    },
    state: {
      type: Object,
      require: true
    },
    minBet: {
      type: Number,
      require: true
    },
    maxBet: {
      type: Number,
      require: true
    },
    numberLimit: {
      type: Number,
      require: true
    },
    increaseRate: {
      type: Number,
      require: true
    },
    balance: {
      type: Number,
      require: true
    },
    currency: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      betAmount:0
    }
  },
  filters: {},
  computed: {},
  mounted() {

  },
  methods: {
    takeDouble: function () {
      if (!this.isDisabled) {
        this.betAmount = 2 * this.betAmount;
        if(this.betAmount >= this.maxBet){
          this.betAmount = this.maxBet;
        }
      }
    },
    takeHalf: function () {
      if (!this.isDisabled) {
        this.betAmount = this.betAmount / 2;
        if(this.betAmount <= this.minBet){
          this.betAmount = this.minBet;
        }
      }
    },
  },
  watch: {
    "state.currencyName":function (){
      this.betAmount = this.state.minBet;
    },
    "betAmount": function (x) {
      if(x >= this.maxBet){
        this.betAmount =  Number(this.maxBet).toFixed(this.numberLimit);
      }else if(this.betAmount <= this.minBet){
        this.betAmount =  Number(this.minBet).toFixed(this.numberLimit)
      }else{
        this.betAmount = Number(x).toFixed(this.numberLimit);
      }
      this.state.setBetAmount(this.betAmount);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.row select,
.row input {
  outline: none !important;
  font-size: 15px;
  padding: 5px;
  border-radius: 6px;
  background: rgb(0 0 0 / 20%);
  color: #fff;
  border: 3px solid rgb(255 255 255 / 22%);
  font-weight: 800;
  width: 100%;
}


.row {
  display: flex;
  padding: 5px 10px;
}


span.btn {
  background: transparent;
  padding: 10px;
  cursor: pointer;
  font-family: 'Roboto';
  font-weight: bold;
}

.row {
  display: flex;
  padding: 5px 10px;
}

.row.btn {
  background: #15232c;
  margin: 5px 10px;
  padding: 5px;
  border-radius: 4px;
}

select.disabled,
.row.btn.disabled > *,
.action.btn.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.row > * {
  margin: 5px;
}

span.btn:hover {
  background: #fff;
  color: #000;
  border-radius: 4px;
}

span.btn sub {
  position: absolute;
  padding-top: 1px;
  padding-left: 2px;
}

.row.table > * {
  display: flex;
  width: 100%;
}

.row.table > *:last-child {
  text-align: right;
  justify-content: right;
}

.row.label {
  padding: 0 10px;
  margin-bottom: -10px;
}

.row select > option {
  background: #15232c;
}

.row select:hover,
.row input:hover {
  border: 3px solid #2196f3;
}
</style>