<template>
  <div className="game-zone">
    <div class="game-wrapper">
      <div class="card-container" @click="state.select(i)" v-for="i in state.gameTable" v-bind:key="i.index">
        <div class="game-card" v-bind:class="{ selected: i.status===1 || state.status === 4 }">

          <div v-if="i.status===1 || state.status === 4">
            <div class="result-container"
                 v-bind:class="{ selected: i.status===1 , 'non-selection': i.selected===false}">
              <img class="bomb" :src="bomb" v-if="i.type===1"/>
              <img class="diamond" :src="diamond" v-if="i.type===0"/>
              <div class="bomb-effect" v-if="i.type===1 && state.clickIndex === i.index">
                <img class="bombEffect" :src="bombEffect1"/>
                <img class="bombEffect" :src="bombEffect2"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="winner-display" v-if="state.isWinner">
        <span class="multip">{{ formatPrice(state.multiplier) }}x</span>
        <span><span class="sperator"></span></span>
        <span class="winned-price">{{ formatPrice(state.winAmount) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Game',
  async mounted() {
    if(!window.mimikoClient.Token){
      return false;
    }
    let gameTable = [];
    for (let i = 0; i < 25; i++) {
      gameTable.push({
        index: i,
        selected: false,
        status: 0,
        type: 0
      })
    }
    this.state = this.$attrs.state;
    this.state.gameTableUpdate(gameTable);

    let data = {
      Token: window.mimikoClient.Token,
      SiteId: Number(window.mimikoClient.SiteId),
      CurrencyId:   Number(window.mimikoClient.CurrencyId),
    }


     const rawResponse = await fetch(this.state.endpoint + 'session', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const response = await rawResponse.json();
    if(response.Error){

      this.state.isError = true;
      this.state.errorMsg = response.Error;

    } else{
      if(response.Balance){
        this.state.balance = response.Balance;
      }
      if(response.SessionId){
        //kaldığın yerden devam et
        this.state.betAmount = Number(response.BetAmount);
        this.state.mineCount = Number(response.MineAmount);
        if(response.CurrentMultiplier) {
          this.state.multiplier = Number(response.CurrentMultiplier);
        }
        this.state.setGameState(3); //oyuna devam et
        //seçili olanları yükle
        let $i = 0;
        response.SelectedBoxIndexes.map(i=>{
          if(i===true){
            this.state.gameTable[$i].selected = true;
            this.state.gameTable[$i].status = 1;
            this.state.gameTable[$i].type = 0;
          }
          $i++;
        })
      }else{

        this.state.setGameState(1); //init done idle
      }
    }

  },
  methods:{
    formatPrice: function (x){
      return Number(x).toFixed(this.state.numberLimit);
    },
  },
  data() {
    return {
      /**
       *  state.status
       *  0 init (bu kısımda oyun var mı hesabı yapılacak)
       *  1 ide
       *  2 loading
       *  3 cashin yapıldı ready
       *  4 oyun başarısız
       */
      bomb: require('../assets/bomb.svg'),
      bombEffect1: require('../assets/bombEffect1.svg'),
      bombEffect2: require('../assets/bombEffect2.svg'),
      diamond: require('../assets/diamond.svg'),
      state: {}
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.game-card {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.game-card {
  background: #2f4553;
  display: inline-block;
  width: 100px;
  height: 100px;
  top: -7px;
  left: 0;
  position: absolute;
  border-radius: 6px;

}
.result-container.non-selection>img {
  opacity: 0.4;
  padding: 25px;
}

.game-card:hover {
  top: 0;
  cursor: pointer;

  background: #7e9eb3;
}

.game-card.selected {
  top: 0;
  background: #1a2c38;
}

.card-container {
  background: #213743;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 10px;
  position: relative;
  box-shadow: 0 3px 2px rgb(0 0 0 / 10%);
  border-radius: 6px;
}

@keyframes bombEffect {
  0% {
    opacity: 0;
    padding: 10px
  }
  25% {
    opacity: 0;
    padding: 0
  }
  50% {
    opacity: 1
  }
  75% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}
.winner-display {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 100px;
  width: 250px;
  background: #15232c;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  border: 4px solid #15e703;
}

.winner-display>* {
  display: flex;
  flex: 1;
}

span.winned-price {
  color: #15e703;
  font-size: 16px;
}


span.sperator {
  display: block;
  background: #2f4553;
  width: 25px;
  height: 3px;
}

span.multip {
  font-size: 26px;
  font-weight: 900;
}
@keyframes selectEffect {
  0% {
    padding: 50px;
  }
  25% {
    padding: 0
  }
  50% {
    padding: 15px
  }
  75% {
    padding: 20px
  }
  100% {
    padding: 15px
  }
}

.result-container.selected > img {
  animation-name: selectEffect;
  animation-duration: 1s;
}

.bomb-effect {

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.bomb-effect > * {
  position: absolute;
  left: 0;
  top: 0;

  opacity: 0;
  animation-name: bombEffect;
  animation-duration: 0.3s;
}

.bomb-effect > *:last-child {
  animation-delay: 0.1s;
}

img.diamond,
img.bomb {
  padding: 15px;
}

img.bomb {
  position: absolute;
  left: 0;
  top: -5px;
}

.game-wrapper {
  padding: 10px 120px 5px;
  background: rgb(0 0 0 / 20%);
  position: relative;
}
@media screen and (max-width: 1100px) {
  .game-wrapper {
    padding: 10px 6% 5px;
  }
}
</style>
